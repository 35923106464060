import { addMinutes } from "date-fns";

import { AfterDownloadParams } from "/concerns/medium/[slug]/Page";
import { MediumId, trackDownloadApi } from "/routesApi/media";
import { getAnonymousId } from "/util/analytics/analytics";
import trackEvent, { trackStructuredEvent } from "/util/analytics/trackEvent";
import isBrowser from "/util/isBrowser";

import { mutateApi } from "../api/fetch";

const searchQuerySessionKey = "searchQuery";

interface SearchQuerySession {
  searchQuery: string;
  expiryTime: Date;
}

export function isSearchQuerySession(value: unknown): value is SearchQuerySession {
  if (!value) {
    return false;
  }

  if (!(value as SearchQuerySession)?.searchQuery) {
    return false;
  }

  if (!(value as SearchQuerySession)?.expiryTime) {
    return false;
  }

  return true;
}

export function setSearchQueryInSession(searchQuery: string) {
  if (!searchQuery) {
    return;
  }

  if (!isBrowser()) {
    return;
  }

  const minutesToBeValid = 5;
  const expiryTime = addMinutes(new Date(), minutesToBeValid);
  const sessionStore: SearchQuerySession = {
    searchQuery,
    expiryTime,
  };

  if (!sessionStorage) {
    return;
  }

  sessionStorage.setItem(searchQuerySessionKey, JSON.stringify(sessionStore));
}

function getSearchQueryFromSession() {
  if (!isBrowser()) {
    return null;
  }

  if (!sessionStorage) {
    return null;
  }

  const searchSession = sessionStorage.getItem(searchQuerySessionKey);

  try {
    const isSearchPage = window.history.state?.url?.includes("search");

    if (!isSearchPage) {
      return null;
    }

    const searchQuery = searchSession ? JSON.parse(searchSession) : null;

    if (!isSearchQuerySession(searchQuery)) {
      return null;
    }

    const { expiryTime, searchQuery: searchTerm } = searchQuery;
    const isExpired = new Date() > new Date(expiryTime);

    if (isExpired) {
      return null;
    }

    return searchTerm;
  } catch (e) {
    Sentry.captureException(e);

    return null;
  }
}

export default function trackDownload({
  trackingKey: action,
  mediumId,
  type,
  size,
  location,
  dimension,
  index,
}: AfterDownloadParams & { mediumId: MediumId<string | number>; index?: number }) {
  const searchQuery = getSearchQueryFromSession();
  const trackingId = getAnonymousId();
  const isGrid = location === "grid";

  trackEvent({
    category: "download",
    action,
    label: `${mediumId}`,
    name: "download",
    id: `${mediumId}`,
    content_type: type,
    size,
    dimension,
    location,
  });

  trackStructuredEvent({
    category: `download${isGrid ? `-${location}` : ""}`,
    action: isGrid ? searchQuery || "" : size || "original",
    label: `${mediumId}`,
    property: type,
    value: typeof index === "number" ? index + 1 : undefined,
  });

  mutateApi(trackDownloadApi, { id: mediumId, searchQuery, trackingId });
}
