import cn from "classnames";
import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";
import { memo, useCallback, useEffect, useState } from "react";

import { preloadAuthModalHeaderThumbnailForMedium } from "/concerns/auth/AuthModal/functions/preloadAuthModalContent";
import { preloadModalImage } from "/concerns/medium/[slug]/PhotoZoom/PhotoZoom";
import { mediumPagePath } from "/routes/media";
import { getImgixFromMedium, isVideo, Medium } from "/routesApi/media";
import getResponsiveImageSizesForGridItem from "/shared/grids/getResponsiveImageSizesForGridItem";
import { ColumnGap, ColumnSizing, RowSizing } from "/shared/grids/types";
import useIsVisibleInWindow from "/shared/hooks/dom/useIsVisibleInWindow";
import useResponsiveImageStyle from "/shared/hooks/dom/useResponsiveImageStyle";
import useAuth from "/shared/hooks/user/useAuth";
import Featured from "/shared/icons/custom/featured.svg";
import InReview from "/shared/icons/custom/in-review.svg";
import Video from "/shared/icons/custom/video.svg";
import useAfterDownloadAd from "/shared/interactions/Download/AfterDownloadModal/useAfterDownloadAd";
import ImgixMedium from "/shared/util/Imgix/ImgixMedium";
import { PlaceholderImage } from "/shared/util/Imgix/types";
import Link from "/shared/util/Link/Link";
import setSnowplowCustomUrl from "/util/analytics/snowplowCustomUrl";
import { trackStructuredEvent } from "/util/analytics/trackEvent";
import { videoPlaybackEnabled } from "/util/constants";
import removeQueryString from "/util/imgix/removeQueryString";

import VideoTag from "../VideoTag/VideoTag";
import css from "./MediaCard.module.scss";
import MediaActions from "./Overlay/Actions";
import DownloadButton from "./Overlay/DownloadButton";

const OverlayAvatar = dynamic(() => import("./Overlay/Avatar"));
const Badge = dynamic(() => import("../Badge/Badge"));
const trackerItemsPerPage = 24;

export interface Props {
  medium: Medium;
  index?: number;
  allMedia: Medium[];
  estimatedWidth: number;
  autoPlay?: boolean;
  lazyLoad?: boolean;
  sourceSetWidths?: number[];
  showFeaturedBadge?: boolean;
  showInReviewBadge?: boolean;
  columnSizing?: ColumnSizing;
  rowSizing?: RowSizing;
  isRowGrid?: boolean;
  columnGap: ColumnGap;
  className?: string;
  searchQuery?: string;
  pageMediumId?: string;
  isNewPath?: boolean;
  setIsNewPath?: (state: boolean) => void;
}

export function getMediaCardUri(medium: Medium) {
  return `${removeQueryString(getImgixFromMedium(medium))}?auto=compress&cs=tinysrgb&dpr=1&w=500`;
}

function MediaCard({
  medium,
  estimatedWidth,
  autoPlay,
  lazyLoad,
  sourceSetWidths,
  showFeaturedBadge,
  showInReviewBadge,
  columnSizing,
  rowSizing,
  isRowGrid,
  columnGap,
  index = 0,
  allMedia,
  className,
  searchQuery,
  // ID for main medium on medium page
  pageMediumId,
  isNewPath,
  setIsNewPath,
}: Props) {
  const { t } = useTranslation("common");
  const { signedIn } = useAuth();
  const responsiveImageSizes = getResponsiveImageSizesForGridItem({
    isRowGrid: !!isRowGrid,
    columnSizing,
    columnGap,
    rowSizing,
    estimatedWidth,
  });
  const [mousedOver, setMousedOver] = useState(false);
  const { triggerPreload } = useAfterDownloadAd();
  const onMouseEnter = useCallback(async () => {
    setMousedOver(true);
    if (!signedIn) {
      preloadAuthModalHeaderThumbnailForMedium(medium);
    }
    await preloadModalImage(medium);
    triggerPreload();
  }, [medium, triggerPreload, signedIn]);
  const onMouseLeave = useCallback(() => setMousedOver(false), []);
  const containerStyle = useResponsiveImageStyle(medium);
  const [placeholderImage, setPlaceholderImage] = useState<PlaceholderImage | undefined>(undefined);
  const { ref: containerRef, isIntersecting } = useIsVisibleInWindow<HTMLDivElement>({ threshold: 0.7 });
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  // To monitor page impression after every 24 items
  const trackPage = index % trackerItemsPerPage === 0;
  const [wasTracked, setWasTracked] = useState(false);
  const imageRef = useCallback((node: HTMLImageElement) => {
    if (!node) {
      setPlaceholderImage(undefined);

      return;
    }

    setPlaceholderImage({
      src: node.src,
      srcSet: node.srcset,
      sizes: node.sizes,
    });
  }, []);
  const trackCallback = useCallback(() => {
    if (!searchQuery && !pageMediumId) {
      return;
    }

    trackStructuredEvent({
      category: !!searchQuery ? "search_click" : "related_resource",
      action: searchQuery || pageMediumId || "",
      label: medium.id,
      property: medium.type,
      value: typeof index === "number" ? index + 1 : undefined,
    });
  }, [medium, searchQuery, pageMediumId, index]);

  useEffect(() => {
    if (!wasTracked) {
      return;
    }

    if (isNewPath) {
      setWasTracked(false);
    }
  }, [isNewPath, wasTracked]);

  useEffect(() => {
    if (!isIntersecting || !trackPage) {
      return;
    }

    // Prevent tracking on initial page which is already tracked in <Head />
    if (isNewPath) {
      setIsNewPath?.(false);
      setWasTracked(true);

      return;
    }

    if (wasTracked) {
      return;
    }

    const currentPage = index / trackerItemsPerPage + 1;

    setWasTracked(true);
    setSnowplowCustomUrl(currentPage);
    snowplow("trackPageView:sp_pap");
  }, [isIntersecting, index, wasTracked, isNewPath, setIsNewPath, trackPage]);

  const canDownload = videoPlaybackEnabled || !isVideo(medium);

  return (
    <article
      className={cn(css.card, className)}
      style={containerStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={trackPage ? containerRef : null}
    >
      {/* Main media, rendered first to have lower z-index than overlay buttons */}
      <Link
        href={(i18n) => mediumPagePath({ i18n, medium })}
        className={css.content}
        popupData={{ medium, placeholderImage, allMedia }}
        hoverAnimation={false}
        trackName="medium_card"
        trackLabel={medium.attributes.title || medium.id}
        trackValue={medium.id}
        trackCallback={trackCallback}
      >
        <ImgixMedium
          ref={imageRef}
          medium={medium}
          w={estimatedWidth}
          lazy={lazyLoad ? "load" : undefined}
          sizesAttrBreakpoints={responsiveImageSizes}
          sourceSetWidths={sourceSetWidths}
          src={getMediaCardUri(medium)}
        />
        {isVideo(medium) && videoPlaybackEnabled && (
          <VideoTag
            layout="fixed"
            autoPlay={autoPlay}
            video={medium}
            mouseOver={mousedOver}
            setIsPlaying={setVideoIsPlaying}
          />
        )}
        <div className={css.overlay} />
      </Link>

      {/* Top overlay - badges and collect/like buttons */}
      <div>
        {showFeaturedBadge && medium.attributes.starred && (
          <Badge
            icon={Featured}
            title={t("featured_title", { mediumType: medium.type })}
            text={t("featured")}
            hideTextUntilHovered
          />
        )}
        {showInReviewBadge && medium.attributes.pending && <Badge icon={InReview} text={t("in_review")} />}
        {isVideo(medium) && <Badge icon={Video} shouldPulse={videoPlaybackEnabled && !videoIsPlaying && mousedOver} />}

        <MediaActions
          className={css.button}
          medium={medium}
          searchQuery={searchQuery}
          index={index}
          isMousedOver={mousedOver}
        />
      </div>

      {/* Bottom overlay - uploader avatar and download btn */}
      <div data-hoveronly className={css.overlayBottom}>
        {mousedOver && <OverlayAvatar medium={medium} />}
        {canDownload && <DownloadButton medium={medium} index={index} isRowGrid={isRowGrid} />}
      </div>
    </article>
  );
}

export default memo(MediaCard);
