import cn from "classnames";
import useTranslation from "next-translate/useTranslation";

import { getDownloadHrefFromMedium, Medium } from "/routesApi/media";
import { useModal } from "/shared/hooks/state/useModal";
import Download from "/shared/icons/custom/file_download.svg";
import AfterDownloadModal from "/shared/interactions/Download/AfterDownloadModal/AfterDownloadModalLoader";
import { ButtonLink } from "/shared/ui/Button/Button";
import Icon from "/shared/ui/Icon/Icon";
import trackDownload from "/util/analytics/trackDownload";

import css from "./DownloadButton.module.scss";

interface Props {
  medium: Medium;
  index: number;
  isRowGrid?: boolean;
}

export default function DownloadButton({ medium, index, isRowGrid }: Props) {
  const { width, height, aspect_ratio: aspectRatio } = medium.attributes;
  const { showModal } = useModal();
  const { t } = useTranslation("common");
  const isVerticalImage = aspectRatio < 1;
  // Show download icon alone on fixed height grid vertical images
  const showOnlyIconOnDesktop = isRowGrid && isVerticalImage;

  return (
    <ButtonLink
      theme="white$FFFFFF"
      download
      className={cn(css.downloadButton, css.fullButtonOnDesktop, {
        [css.showOnlyIconOnDesktop]: showOnlyIconOnDesktop,
      })}
      title={t("download")}
      href={getDownloadHrefFromMedium(medium)}
      onClick={() => {
        trackDownload({
          trackingKey: "grid",
          mediumId: medium.id,
          type: medium.type,
          dimension: `${width}x${height}`,
          location: "grid",
          index,
        });
        showModal(AfterDownloadModal, { medium });
      }}
      icon={<Icon symbol={Download} />}
    >
      <span className={css.downloadButtonText}>{t("download")}</span>
    </ButtonLink>
  );
}
