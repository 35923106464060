import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";
import { useCallback } from "react";

import AuthModal from "/concerns/auth/AuthModal/AuthModal";
import { AuthIntentType } from "/concerns/auth/AuthModal/types";
import { Medium } from "/routesApi/media";
import { useModal } from "/shared/hooks/state/useModal";
import useAuth from "/shared/hooks/user/useAuth";

const CollectionModal = dynamic(() => import("/shared/interactions/Collection/CollectionModal/CollectionModal"));

interface Props {
  medium: Medium;
}

export default function useCollectButtonState({ medium }: Props) {
  const { t } = useTranslation();
  const { signedIn } = useAuth();
  const { showModal } = useModal();

  const collected = medium.attributes.collection_ids.length > 0;
  const text = collected ? t("common:collects.collected") : t("common:collects.collect");

  const onClick = useCallback(() => {
    if (!signedIn) {
      showModal(AuthModal, {
        intent: {
          type: AuthIntentType.collectMedium,
          datum: medium,
        },
      });
      return;
    }

    showModal(CollectionModal, { medium: medium });
  }, [signedIn, showModal, medium]);

  return { text, collected, onClick };
}
