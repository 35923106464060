import Flex from "/shared/ui/Flex/Flex";
import LoadingBox from "/shared/ui/LoadingBox/LoadingBox";
import GenericSpaceItem from "/shared/ui/SpacingGroup/GenericSpaceItem";
import SpacingGroup from "/shared/ui/SpacingGroup/SpacingGroup";

export default function ModalContentLoadingDummy() {
  return (
    <Flex>
      <LoadingBox height={275} width={200} />

      <GenericSpaceItem paddingTop={30} paddingBottom={30} paddingRight={30} paddingLeft={30}>
        <LoadingBox height={40} width="50%" marginBottom={8} />
        <LoadingBox height={20} width="75%" marginBottom={15} />

        <SpacingGroup spaceBetween={8} marginBottom={15}>
          <LoadingBox height={50} width={120} />
          <LoadingBox height={50} width={50} />
          <LoadingBox height={50} width={50} />
        </SpacingGroup>

        <LoadingBox height={50} width="100%" />
      </GenericSpaceItem>
    </Flex>
  );
}
