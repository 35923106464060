import { isVideo, Medium } from "/routesApi/media";
import useMemoStyle from "/shared/hooks/dom/useMemoStyle";
import { colorMap } from "/types/colors";

export function getBackgroundImageColor(medium: Medium) {
  if (isVideo(medium)) {
    return colorMap.greyMinus2$DFDFE0.hex;
  }

  return `rgb(${medium.attributes.main_color.join(", ")})`;
}

// More info about this hack: css-tricks.com/aspect-ratio-boxes/
function getPaddingTop({ attributes: { height, width } }: Medium) {
  return `${(height / width) * 100}%`;
}

export default function useResponsiveImageStyle(medium: Medium) {
  return useMemoStyle({
    paddingTop: getPaddingTop(medium),
    background: getBackgroundImageColor(medium),
  });
}
