import dynamic from "next/dynamic";

import ModalContentLoadingDummy from "./ModalContentLoadingDummy";

const modalImport = import("/shared/interactions/Download/AfterDownloadModal/AfterDownloadModal");

/**
 * Trying to keep a single instance of this import, rather than each location that triggers this modal having its own
 * dyanmic import.
 *
 * In testing, this seemed to help with the Safari-specific issue of image/video downloads blocking the after-download
 * modal from appearing.
 */
const AfterDownloadModal = dynamic(() => modalImport, { loading: ModalContentLoadingDummy });

/**
 * A dummy component that is always invisible, but rendering this component has side-effect of preloading the
 * AfterDownloadModal component.
 */
export const AfterDownloadModalPreloader = dynamic(() =>
  modalImport.then(() => {
    return () => null;
  }),
);

export default AfterDownloadModal;
