import { useCallback } from "react";

import { Medium } from "/routesApi/media";
import Collection from "/shared/icons/custom/collection.svg";
import Favorite from "/shared/icons/custom/favorite.svg";
import useCollectButtonState from "/shared/interactions/Collection/CollectButton/useCollectButtonState";
import useLikeState from "/shared/interactions/Like/useLikeState";
import Button from "/shared/ui/Button/Button";
import Icon from "/shared/ui/Icon/Icon";
import { trackStructuredEvent } from "/util/analytics/trackEvent";

import css from "./Actions.module.scss";

interface Props {
  medium: Medium;
  className?: string;
  index?: number;
  searchQuery?: string;
  isMousedOver?: boolean;
}

export default function MediaActions({ medium, className, searchQuery, index, isMousedOver }: Props) {
  const likeState = useLikeState({ medium });
  const collectState = useCollectButtonState({ medium });
  const trackCallback = useCallback(
    (category: string) => {
      trackStructuredEvent({
        category: `${category}-grid`,
        action: searchQuery || "",
        label: medium.id,
        property: medium.type,
        value: typeof index === "number" ? index + 1 : undefined,
      });
    },
    [medium, searchQuery, index],
  );

  const handleClick = (category: string, cb: () => void) => {
    trackCallback(category);
    cb();
  };

  return (
    <>
      <Button
        className={className}
        theme={collectState.collected ? "jellyfish" : "transparent"}
        onClick={() => handleClick("collect", collectState.onClick)}
        title={collectState.text}
        icon={<Icon symbol={Collection} />}
        data-hoveronly={!collectState.collected}
      />

      <div
        className={css.actionButtons}
        style={{ marginRight: isMousedOver || likeState.alreadyLiked ? "0" : "-49px", opacity: 1 }}
      >
        <Button
          className={className}
          data-active={likeState.alreadyLiked}
          theme={likeState.alreadyLiked ? "strawberry" : "transparent"}
          onClick={() => handleClick("like", likeState.onClick)}
          disabled={likeState.disabled}
          aria-disabled={false}
          title={likeState.text}
          icon={<Icon symbol={Favorite} />}
          data-hoveronly={!likeState.alreadyLiked}
        />
      </div>
    </>
  );
}
