import useTranslation from "next-translate/useTranslation";
import { useCallback, useState } from "react";

import AuthModal from "/concerns/auth/AuthModal/AuthModal";
import { AuthIntentType } from "/concerns/auth/AuthModal/types";
import { Medium } from "/routesApi/media";
import { useModal } from "/shared/hooks/state/useModal";
import useAuth from "/shared/hooks/user/useAuth";
import useUserState from "/shared/hooks/user/useUserState";

export interface LikeStateProps {
  medium: Medium;
  onSuccess?: () => void;
}

export default function useLikeState({ medium }: LikeStateProps) {
  const { mediaIdsLiked, likeMedium } = useUserState();
  const { id } = medium.attributes;
  const i18n = useTranslation();
  const { t } = i18n;
  const { signedIn } = useAuth();
  const likedState = mediaIdsLiked[id];
  const [disabled, setDisabled] = useState(false);
  const { showModal } = useModal();
  const onClick = useCallback(async () => {
    if (disabled) {
      return;
    }

    if (!signedIn) {
      showModal(AuthModal, {
        intent: {
          type: AuthIntentType.likeMedium,
          datum: medium,
        },
      });

      return;
    }

    setDisabled(true);
    await likeMedium(medium, !likedState);
    setDisabled(false);
  }, [disabled, likeMedium, likedState, medium, signedIn, showModal]);
  const text = likedState ? t("common:likes.liked") : t("common:likes.unliked");

  return {
    text,
    alreadyLiked: likedState,
    disabled,
    onClick,
  };
}
